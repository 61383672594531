import { useAuth } from '@provider/AuthContext';
const useTermsOfUseAccepted = () => {
  const { user, isLoading, isRefetching } = useAuth();

  const isTermOfUseAccepted = user?.isTermOfUseAccepted;

  return { isTermOfUseAccepted, isLoading, isRefetching };
};

export default useTermsOfUseAccepted;
