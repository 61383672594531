import '@mantine/core/styles.css';
import './index.css';
import GlobalErrorBoundaryUI from '@components/Base/GlobalErrorBoundary/GlobalErrorBoundary.tsx';
import FeedbackFloatButton from '@components/Buttons/Feedback';
import ErrorBoundaryContainer from '@components/ErrorBoundaryContainer/ErrorBoundaryContainer.tsx';
import ModalSessionExprired from '@components/ModalSessionExprired/ModalSessionExprired.tsx';
import { config } from '@constants/config.ts';
import { useGetCurrentUserProfile } from '@hooks/useGetCurrentProfile.tsx';
import { AxiosProvider } from '@provider/AxiosContext.tsx';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { convertToUser } from '@utils/helpers.ts';
import { lazy, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { AppProvider } from './providers/AppContext.tsx';
import { AuthProvider, useAuth, User } from './providers/AuthContext.tsx';
import { routeTree } from './routeTree.gen';
import { queryClient } from './useQueries/queryClientConfig.ts';

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  context: {
    authentication: undefined!,
  },
  defaultNotFoundComponent: lazy(() => import('@components/pages/NotFound')),
  defaultErrorComponent: lazy(
    () => import('@components/Base/RouterErrorBoundary/RouterErrorBoundary.tsx'),
  ),
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const authentication = useAuth();
  const { setUser, user, setIsLoading, setIsRefetching } = authentication;

  const localStorageData = localStorage.getItem('xa-network')
    ? JSON.parse(localStorage.getItem('xa-network') as string)
    : null;

  const {
    data: memberProfile,
    isRefetching,
    isLoading,
  } = useGetCurrentUserProfile(localStorageData?.recordId || '');

  const memberProfileData = useMemo(() => {
    if (memberProfile) return memberProfile;
    if (localStorageData) return localStorageData;
    return null;
  }, [localStorageData, memberProfile]);

  useEffect(() => {
    if (setIsRefetching) setIsRefetching(isRefetching);
    if (setIsLoading) setIsLoading(isLoading);
    if (!user && memberProfileData) {
      const dataConverted = convertToUser(memberProfileData);
      const data: User = {
        jwt: localStorageData?.jwt,
        ...dataConverted,
      };
      setUser(data);
    }
  }, [
    isLoading,
    isRefetching,
    localStorageData,
    memberProfileData,
    setIsLoading,
    setIsRefetching,
    setUser,
    user,
  ]);

  return <RouterProvider router={router} context={{ authentication }} />;
}

export default function App() {
  if (config.NODE_ENV !== 'development') {
    ReactGA.initialize(config.GA_TRACKING_ID);
  }

  return (
    <ErrorBoundaryContainer fallbackComponent={<GlobalErrorBoundaryUI />}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <AuthProvider>
            <AxiosProvider>
              <main className='relative flex flex-col h-screen max-h-screen'>
                <InnerApp />
                <FeedbackFloatButton />
                <ModalSessionExprired />
              </main>
              <Toaster />
              <ReactQueryDevtools initialIsOpen={false} />
            </AxiosProvider>
          </AuthProvider>
        </AppProvider>
      </QueryClientProvider>
    </ErrorBoundaryContainer>
  );
}
