import useLogout from '@hooks/useLogout';
import { Group, Avatar, Text } from '@mantine/core';
import { User } from '@provider/AuthContext';
import { IconLogout } from '@tabler/icons-react';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import TooltipButton from './TooltipButton';

export function UserButton({ user }: { user: User | null }) {
  const navigate = useNavigate();
  const { handleLogout: handleLogoutContext } = useLogout();

  const handleLogout = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      handleLogoutContext();
    },
    [handleLogoutContext],
  );

  const handleGoToProfile = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      navigate({
        to: '/my-profile',
      });
    },
    [navigate],
  );

  return (
    <div className='flex items-center justify-between'>
      <div
        className='w-full p-4 text-white hover:bg-secondary-black'
        onClick={(e) => handleGoToProfile(e)}
      >
        <Group wrap='wrap'>
          <div className='relative'>
            <Avatar src={user?.profilePhoto || user?.picture} radius='xl' />
          </div>

          <div className='cursor-pointer'>
            <Text size='sm' fw={500}>
              {user?.name || user?.preferredName}
            </Text>

            <Text c='dimmed' size='xs'>
              {user?.email}
            </Text>
          </div>
        </Group>
      </div>
      <TooltipButton
        className='mx-2'
        icon={IconLogout}
        label='Logout'
        onClick={(e) => handleLogout(e)}
      />
    </div>
  );
}
