import { UnstyledButton, Group, Avatar } from '@mantine/core';
import { User } from '@provider/AuthContext';
import { useSideBarStore } from '@store/useSideBarStore';

export function UserButtonSmall({ user }: { user: User | null }) {
  const setIsExpanded = useSideBarStore((state) => state.setIsExpanded);

  return (
    <UnstyledButton
      className='w-full p-4 text-white hover:bg-secondary-black'
      onClick={() => {
        setIsExpanded(true);
      }}
    >
      <Group>
        <div className='relative'>
          <Avatar src={user?.profilePhoto || user?.picture} radius='xl' />
        </div>
      </Group>
    </UnstyledButton>
  );
}
