import { useSessionExpired } from '@hooks/useSessionExpired';
import { useAuth } from '@provider/AuthContext';
import { IconX, IconAlertCircle } from '@tabler/icons-react';
import { cn } from '@utils/utils';
import { useEffect, useState } from 'react';

const ModalSessionExprired = () => {
  const { setUser } = useAuth();
  const { isSessionExpired, clearSession } = useSessionExpired();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    clearSession();
  };

  const handleClick = () => {
    setUser(null);
    clearSession();
    window.location.href = '/login';
  };

  useEffect(() => {
    setIsOpen(isSessionExpired);
  }, [isSessionExpired]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={cn(
        `fixed top-0 bottom-0 left-0 right-0 z-50 bg-gray/85 flex justify-center items-center`,
      )}
    >
      <div className={cn(`bg-white shadow-xl rounded-xl py-10 px-16 relative`)}>
        <button className={cn(`absolute top-4 right-4`)} onClick={handleClose}>
          <IconX />
        </button>
        <div className={cn(`flex justify-center items-center`)}>
          <IconAlertCircle width={48} height={48} />
        </div>
        <h2 className={cn(`text-xl text-center mt-4`)}>Session Exprired!</h2>
        <p className={cn(`mt-6`)}>
          Your session has expired. Please log in again to continue using the application.
        </p>
        <button
          onClick={handleClick}
          className={cn(
            `bg-dark-red w-full py-2 rounded-xl text-white font-semibold mt-4 hover:bg-dark-medium-red transition`,
          )}
        >
          Log In Again
        </button>
      </div>
    </div>
  );
};

export default ModalSessionExprired;
