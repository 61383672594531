import { COMPANY_STATUS, SPV } from '@constants/enums';

export interface CompanyLogo {
  id: string;
  width: number;
  height: number;
  url: string;
  filename: string;
  size: number;
  type: string;
  thumbnails: {
    small: {
      url: string;
      width: number;
      height: number;
    };
    large: {
      url: string;
      width: number;
      height: number;
    };
    full: {
      url: string;
      width: number;
      height: number;
    };
  };
}

export enum CEASED_OPERATIONS {
  Y = 'Y',
  N = 'N',
}

export interface Company {
  'Company Name': string;
  '[T] Pipeline': string[];
  Country: string;
  Sector: string[];
  Description: string;
  Website: string;
  Logo: CompanyLogo[];
  '[M] XA Members': string;
  'Co-investors': string;
  'Investment Date': string;
  'Checked?': boolean;
  'Total Invested Amount (USD)': number;
  'Cohort (from [T] Pipeline)': string[];
  'Total Follow-On (dollars)': number;
  'Status (from [T] Pipeline)': string[];
  'Exited/ Ceased': number;
  MOIC: number;
  'Date Money in Bank (from Deal ID)': string[];
  'Days Elapsed': number;
  Type: string;
  'Post-Money Valuation (USD) (from [T] Pipeline)': number[];
  'Have a quarter passed since last check?': number;
  'Round (from [T] Pipeline)': string[];
  'Latest round check': string;
  Today: string;
  'Cohort (from [T] Pipeline) 2': string[];
  'Round Size (USD) (from [T] Pipeline)': number[];
  Calculation: {
    specialValue: string;
  };
  'Total Invested Amount without Ignite (USD)': number;
  'Total Investment w/o Ignite Q4 2022': number;
  'Weight MOIC Q4 2022': number;
  'Total Investment w/o Ignite Q1 2023': number;
  'Weight MOIC Q1 2023': number;
  'Date added (from [T] Pipeline)': string[];
  '[Synced] Member (from [T] Investments)': string[];
  'Latest Share Price'?: number;
  'Status (APE)'?: COMPANY_STATUS;
  'Last Valuation'?: number;
  'Company ID'?: string;
  'Ceased Operations'?: CEASED_OPERATIONS;
}

export interface Investment {
  'Deal ID': string[];
  'Investment Amount (USD)': number;
  'Confirmation Email': string;
  '[Synced] Member Name': string[];
  'Investment ID': string;
  'Email Address (from [Synced] Member Name)': string[];
  'Company Name (from Deal ID)': string[];
  'Date Money in Bank (from Deal ID)': string[];
  'Date Money in Bank (from [T] Pipeline)': string[];
  'Status (from Deal ID)': string[];
  'Last Modified': string;
  'Post-Money Valuation (USD) (from Deal ID)': number[];
  'Round (from Deal ID)': string[];
  'Sector (from Company Name) 2 (from Deal ID)': string[];
  'Country (from Company Name) 2 (from Deal ID)': string[];
  'Preferred Name (from [Synced] XA Members 2)': string[];
  'LinkedIn URL (from [Synced] Member Name)': string[];
  'Logo (from Company Name) (from Deal ID)': CompanyLogo[];
  'Lead Member (from Company Name) (from Deal ID)': string | null;
  'Lead Member LinkedIn (from Company Name) (from Deal ID)': string;
  'Estimated Current Value of Investment': number;
  Calculation: string;
  Member: string;
  company: Company & { totalShares?: number; totalInvested?: number };
  subRows?: Investment[];
  investmentAmountList?: number[];
  SPV?: SPV;
  numberOfShares?: number;
}

export type TableFilterType = 'checkbox' | 'radio';
export interface ChildFormFilterConfig {
  name: string;
  type: TableFilterType;
  label: string;
  options?: [any, any];
}

export interface FormFilterConfig {
  forms: ChildFormFilterConfig[];
}
