import { useLoginWithLinkedin } from '@api/mutations/useLoginWithLinkedin';
import { useGetLinkedinAccessToken } from '@api/queries/useGetLinkedinAccessToken';
import { useGetLinkedinProfile } from '@api/queries/useGetLinkedinProfile';
import { useLinkedinRedirect } from '@api/queries/useLinkedinRedirect';
import LinkedinButton from '@components/Buttons/LinkedinButton';
import { SCREEN_SIZE } from '@constants/enums';
import { useWindowSize } from '@hooks/useWindowSize';
import { Text, Paper, Group, Stepper, Loader } from '@mantine/core';
import { useAuth, User } from '@provider/AuthContext';
import { ParsedLocation, useLocation, useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { LOGIN_STEPS } from './AuthenticationForm.const';
import UserNotFound from './UserNotFound';

type LoginFormURLParams = {
  code: string;
  state: string;
};

export function LoginForm() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate({ from: '/login' });
  const { width } = useWindowSize();
  const location: ParsedLocation<LoginFormURLParams> = useLocation();

  const {
    mutateAsync: loginWithLinkedin,
    isPending: isLoginLinkedinPending,
    isError: isLoginError,
  } = useLoginWithLinkedin();

  const { linkedinRedirectUrl } = useLinkedinRedirect();
  const { linkedinAccessToken } = useGetLinkedinAccessToken(location.search.code);
  const { linkedinProfile, isLoading } = useGetLinkedinProfile(linkedinAccessToken?.access_token);

  const handleLinkedinLogin = async () => {
    if (linkedinRedirectUrl) {
      window.location.href = linkedinRedirectUrl;
    }
  };
  // State
  const [isNotFoundUser, setIsNotFoundUser] = useState(false);

  const handleLoginSuccess = useCallback(
    (user: User, jwt: string, refreshToken: string) => {
      toast.success('Successfully logged in !');
      localStorage.setItem(
        'xa-network',
        JSON.stringify({
          ...user,
          jwt,
          refresh_token: refreshToken,
        }),
      );
      setUser({ ...user, jwt });
      setTimeout(() => {
        navigate({ to: '/home', viewTransition: true });
      }, 1000);
    },
    [navigate, setUser],
  );

  const handleLoginFail = () => {
    toast.error('User not found, please contact XA support through email below:');
    setIsNotFoundUser(true);
  };

  const renderStep = useMemo(() => {
    const updatedStepActive =
      isLoading || isLoginLinkedinPending ? LOGIN_STEPS[1] : user ? LOGIN_STEPS[2] : LOGIN_STEPS[0];

    return LOGIN_STEPS.map((step, index) => (
      <Stepper.Step
        key={index}
        label={step.label}
        description={step.description}
        loading={step.label === updatedStepActive.label}
      />
    ));
  }, [isLoading, isLoginLinkedinPending, user]);

  useEffect(() => {
    const getLinkedinCodeAndLogin = async () => {
      if (!linkedinProfile || user) {
        return;
      }

      if (isLoginError) {
        handleLoginFail();
        return;
      }

      const dataProfile = await loginWithLinkedin(linkedinProfile.email);

      const dataToSaveInLocalStorage: User = {
        ...linkedinProfile,
        ...dataProfile,
      };

      handleLoginSuccess(dataToSaveInLocalStorage, dataProfile.jwt, dataProfile.refresh_token);
    };

    try {
      getLinkedinCodeAndLogin();
    } catch (error) {
      toastLoginFail();
      setUser(null);
      console.error(error);
    }
  }, [user, isLoginError, linkedinProfile, handleLoginSuccess, loginWithLinkedin, setUser]);

  if (isNotFoundUser) {
    return (
      <UserNotFound
        onBack={() => {
          setIsNotFoundUser(false);
          navigate({ to: '/login' });
        }}
      />
    );
  }

  if (isLoginLinkedinPending || linkedinProfile || user) {
    return <Loader color='red' />;
  }

  return (
    <Paper radius='md' p='xl' withBorder className='relative ' pos={'relative'}>
      <Text size='xl' fw={600} className='mb-4 text-center'>
        Welcome to XA Network, login with
      </Text>

      <Group grow mb='md' mt='md'>
        <LinkedinButton
          radius='xl'
          onClick={handleLinkedinLogin}
          size='lg'
          disabled={isLoading || isLoginLinkedinPending || !!user}
        >
          Linkedin
        </LinkedinButton>
      </Group>
      <Stepper
        active={1}
        orientation={width >= SCREEN_SIZE.LG ? 'horizontal' : 'vertical'}
        classNames={{
          steps: 'p-2.5',
          separator: 'hidden lg:block',
          verticalSeparator: 'hidden',
        }}
      >
        {renderStep}
      </Stepper>
    </Paper>
  );
}
function toastLoginFail() {
  toast.error(
    <div className='flex flex-col justify-center'>
      <h1 className='font-bold text-[14px]'>Login failed</h1>
      <p className='text-sm'>Check your email & password</p>
    </div>,
    {
      position: 'top-center',
    },
  );
}
