import { QUERY_KEY } from '@constants/queryKeys';
import { useAxiosClient } from '@provider/AxiosContext';
import { useQuery } from '@tanstack/react-query';

export function useGetLinkedinAccessToken(code?: string) {
  const { axiosInstance } = useAxiosClient();
  const { data, isLoading, error } = useQuery({
    queryKey: [QUERY_KEY.GET_LINKEDIN_ACCESS_TOKEN, code],
    queryFn: async () => {
      const response = await axiosInstance.get<any>(`/auth/linkedin/access-token?code=${code}`);
      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!code,
  });
  return { linkedinAccessToken: data, isLoading, error };
}
