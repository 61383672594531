import ErrorBoundaryContainer from '@components/ErrorBoundaryContainer/ErrorBoundaryContainer';
import { useSideBarStore } from '@store/useSideBarStore';
import { AnimatePresence } from 'framer-motion';
import CollapsedSidebar from './CollapsedSidebar';
import ExpandedSidebar from './ExpandedSidebar';
const SidebarAnimatedWrapper = () => {
  const isExpanded = useSideBarStore((state) => state.isExpanded);
  return (
    <>
      <AnimatePresence mode='wait' initial={true}>
        <ErrorBoundaryContainer>
          {isExpanded ? <ExpandedSidebar /> : <CollapsedSidebar />}
        </ErrorBoundaryContainer>
      </AnimatePresence>
    </>
  );
};

export default SidebarAnimatedWrapper;
