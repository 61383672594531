import { Title, Text, Button, Container, Group } from '@mantine/core';

const GlobalErrorBoundaryUI = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className='font-Roobert py-20 pb-[120px] fixed top-0 left-0 right-0 bottom-0 w-full h-full flex justify-center items-center bg-medium-dark-red'>
      <Container>
        <div className='text-8xl mb-10 text-center font-extrabold text-medium-red lg:text-[220px]'>
          500
        </div>
        <Title className='text-center font-extrabold text-4xl text-white'>
          Something bad just happened...
        </Title>
        <Text size='lg' className='max-w-full my-10 text-center p-4 text-white'>
          Our servers could not handle your request. Don&apos;t worry, our development team was
          already notified. Try refreshing the page.
        </Text>
        <Group justify='center'>
          <Button variant='filled' color='red' size='md' onClick={handleRefresh}>
            Refresh the page
          </Button>
        </Group>
      </Container>
    </div>
  );
};

export default GlobalErrorBoundaryUI;
