import { QUERY_KEY } from '@constants/queryKeys';
import { useAxiosClient } from '@provider/AxiosContext';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export type MemberData = {
  recordsById: Record<string, any>;
};

export function useGetMemberProfile(memberRecordId: string) {
  const { axiosInstance } = useAxiosClient();
  return useQuery({
    queryKey: [QUERY_KEY.GET_MEMBER_PROFILE, memberRecordId],
    queryFn: async () => {
      const response = await axiosInstance.post('/member-profile', {
        userId: memberRecordId,
      });
      return await response.data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
}

export const useFetchAllLocations = () => {
  const { axiosInstance } = useAxiosClient();

  const fetchAllLocations = async (): Promise<string[]> => {
    const res = await axiosInstance.get('/locations');
    return res.data;
  };

  return useQuery({
    queryKey: [QUERY_KEY.GET_ALL_LOCATIONS],
    queryFn: async () => fetchAllLocations(),
    initialData: [],
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};

export const useAllMembers = (): UseQueryResult<MemberData, Error> => {
  const { axiosInstance } = useAxiosClient();
  return useQuery<MemberData, Error>({
    queryKey: [QUERY_KEY.GET_ALL_XA_MEMBERS],
    queryFn: async () => {
      const res = await axiosInstance.get('/members');
      return {
        recordsById: res.data as Record<string, any>,
      };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
