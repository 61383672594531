import { SCREEN_SIZE } from '@constants/enums';
import { useWindowSize } from '@hooks/useWindowSize';
import { LoadingOverlay, MultiSelect, ScrollArea, Skeleton } from '@mantine/core';
import { useAllMembers } from '@queries/bases/xa-member-pipeline/useXaMembersQuery';
// import { IconChevronDown } from '@tabler/icons-react';
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
// import { formatCurrencyWithoutFractionDigits } from '@utils/helpers';
import dayjs from 'dayjs';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTableStore } from '../store/useTableStore';
import { Investment } from '../types/InvestmentTable/type';
import { useGetInvestmentByXAMemberId } from '../useQueries/bases/xa-network-pipeline/useXaNetworkPipeline';
// import { cn } from '../utils/utils';
import AmountInvestedColumn from './MyPortfolioInvestmentTable/Columns/AmountInvestedColumn';
import CompanyNameColumn from './MyPortfolioInvestmentTable/Columns/CompanyNameColumn';
import CurrentValueColumn from './MyPortfolioInvestmentTable/Columns/CurrentValueColumn';
import InvestmentDateColumn from './MyPortfolioInvestmentTable/Columns/InvestmentDateColumn';
import MoicColumn from './MyPortfolioInvestmentTable/Columns/MoicColumn';
import XaLeadColumn from './MyPortfolioInvestmentTable/Columns/XaLeadColumn';
import { MyPortfolioTableFilterFormValues } from './TableFilterContext';
import TableFilterModalForm from './TableFilterModalForm';
import TableView from './TableView';

type VisibleColumnState = Record<string, boolean>;

interface InvesmentTableProps {
  userRecordId: string;
  type?: string;
  isModalOpen?: boolean;
  onOpenModal?: () => void;
  onCloseModal?: () => void;
}

const HIDDEN_COLUMNS: string[] = [];
export const InvesmentTable = ({
  userRecordId,
  type,
  isModalOpen,
  onCloseModal,
}: InvesmentTableProps) => {
  const isMyInvestment = type === 'my-portfolio';
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const [globalTableSearchText, setGlobalSearchText] = useTableStore((state) => [
    state.globalTableSearchText,
    state.setGlobalSearchText,
  ]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const { width } = useWindowSize();
  const isResponsived = width < SCREEN_SIZE.LG;

  const { data: memberInvestment = [], isLoading: isMemberInvestmentLoading } =
    useGetInvestmentByXAMemberId(userRecordId, type);
  const { data: allMembers } = useAllMembers();

  const columns = React.useMemo<ColumnDef<Investment>[]>(
    () => [
      {
        id: 'index',
        meta: 'Index',
        maxSize: 20,
        minSize: 20,
        header: () => <div>No.</div>,
        // cell: ({ row }) => {
        //   const isChild = !!row.parentId;
        //   return (
        //     <div
        //       style={{
        //         paddingLeft: `${row.depth * 2}rem`,
        //       }}
        //     >
        //       {row.getCanExpand() ? (
        //         <button onClick={row.getToggleExpandedHandler()}>
        //           <div className='flex gap-2'>
        //             <span>{row.index + 1}</span>
        //             <span
        //               className={cn('transition duration-300', {
        //                 'rotate-180': row.getIsExpanded(),
        //               })}
        //             >
        //               <IconChevronDown />
        //             </span>
        //           </div>
        //         </button>
        //       ) : (
        //         <span>{isChild ? (Number(row.id) + 1.1).toFixed(1) : row.index + 1}</span>
        //       )}
        //     </div>
        //   );
        // },
        accessorFn: (_, index) => index + 1, // accessor or id is required for some reason
        filterFn: 'equalsString', //note: normal non-fuzzy filter column - exact match required
      },
      {
        size: 180,
        id: 'comapyName',
        meta: 'Company Name',
        accessorFn: (row) => row.company['Company Name'],
        accessorKey: 'company',
        header: () => 'Company Name',
        cell: (info) => {
          const company = info.row.original.company;
          return <CompanyNameColumn company={company} />;
        },
      },
      {
        id: 'dateOfInvestment',
        meta: 'Date Of Investment',
        accessorFn: (row) => {
          if (!row['Date Money in Bank (from Deal ID)']) return null;

          return row['Date Money in Bank (from Deal ID)'].map((date) => {
            return dayjs(date.toString()).format(` MMMM DD,  YYYY`);
          });
        },
        cell: ({ row }) => {
          const dates = row?.original['Date Money in Bank (from Deal ID)'];

          return <InvestmentDateColumn dates={dates} />;
        },
        header: () => <span>Date Of Investment</span>,
      },
      {
        meta: 'XA Lead',
        id: 'xaLead',
        accessorKey: 'Lead Member',
        header: () => 'XA Lead',
        accessorFn: (row) => {
          const leaderName = row['Lead Member (from Company Name) (from Deal ID)']?.[0];
          return leaderName;
        },
        cell: (info) => {
          const leaderName = info.row.original['Lead Member (from Company Name) (from Deal ID)'];
          const leaderId = info.row.original.company['[Synced] Member (from [T] Investments)']?.[0];
          const leaderLinkedIn =
            info.row.original['Lead Member LinkedIn (from Company Name) (from Deal ID)'];
          return (
            <XaLeadColumn
              leaderId={leaderId}
              leaderName={leaderName}
              leaderLinkedIn={leaderLinkedIn}
              membersData={allMembers}
            />
          );
        },
      },
      {
        meta: 'Funding Round',
        id: 'fundingRound',
        accessorKey: 'fundingRound',
        filterFn: 'includesString',
        accessorFn: (row) => {
          return row['Round (from Deal ID)']?.[0];
        },
        header: () => <span>Funding Round</span>,
        cell: (info) => (
          <div className='flex flex-col gap-4'>
            {info.row.original['Round (from Deal ID)'].join(', ')}
          </div>
        ),
      },
      {
        meta: 'Amount Invested',
        id: 'investmentAmount',
        accessorKey: 'Investment Amount (USD)',
        header: 'Amount Invested (USD)',
        cell: ({ row }) => {
          const isChild = !!row.parentId;
          return (
            <AmountInvestedColumn
              isChild={isChild}
              index={row.index}
              totalInvestedAmount={row.original['Investment Amount (USD)']}
              investedAmounts={row.original['investmentAmountList'] ?? []}
            />
          );
        },
        accessorFn: (row) => row['Investment Amount (USD)'] + 'K',
        filterFn: 'equalsString',
      },
      {
        meta: 'Current Value',
        id: 'currentValue',
        header: 'Current Value (USD)',
        cell: (info) => {
          return (
            <CurrentValueColumn
              latestSharePrice={Number(info.row.original.company['Latest Share Price'])}
              investmentAmount={Number(info.row.original['Investment Amount (USD)'])}
              totalShares={info.row.original.company.totalShares}
              sector={info.row.original.company.Sector}
              status={info.row.original.company['Status (APE)']}
              lastValuation={info.row.original.company['Last Valuation']}
              companyID={info.row.original.company['Company ID']}
              spv={info.row.original.SPV}
              name={info.row.original.company['Company Name']}
              ceasedOperations={info.row.original.company['Ceased Operations']}
              numberOfShares={info.row.original.numberOfShares}
            />
          );
        },
      },
      {
        meta: 'MOIC',
        id: 'moic',
        header: 'MOIC',
        cell: (info) => {
          return (
            <MoicColumn
              latestSharePrice={Number(info.row.original.company['Latest Share Price'])}
              investmentAmount={Number(info.row.original['Investment Amount (USD)'])}
              totalShares={info.row.original.company.totalShares}
              sector={info.row.original.company.Sector}
              status={info.row.original.company['Status (APE)']}
              lastValuation={info.row.original.company['Last Valuation']}
              companyID={info.row.original.company['Company ID']}
              spv={info.row.original.SPV}
              name={info.row.original.company['Company Name']}
              ceasedOperations={info.row.original.company['Ceased Operations']}
              numberOfShares={info.row.original.numberOfShares}
            />
          );
        },
      },
      // {
      //   meta: 'Current Value',
      //   id: 'currentValue',
      //   header: 'Current Value (USD)',
      //   cell: (info) => {
      //     if (!info.row.original['Estimated Current Value of Investment'])
      //       return <span>Not specified</span>;
      //     const lastestValuation = info.row.original['Estimated Current Value of Investment'];
      //     return <span>{currencyFormatter(lastestValuation)}</span>;
      //   },
      // },
    ],
    [allMembers],
  );

  const [columnVisibility, setColumnVisibility] = useState<VisibleColumnState>({});

  const tableData = React.useMemo(
    () => (isMemberInvestmentLoading ? [] : memberInvestment),
    [isMemberInvestmentLoading, memberInvestment],
  );

  const tableColumns = React.useMemo(() => {
    if (isMemberInvestmentLoading) {
      return columns.map((column) => ({
        ...column,
        cell: <Skeleton height={40} width={'auto'} />,
      }));
    }

    if (!isMyInvestment) {
      return columns.filter((column) => column.id && !HIDDEN_COLUMNS.includes(column.id));
    }

    return columns;
  }, [isMemberInvestmentLoading, isMyInvestment, columns]);

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as any,
    state: {
      sorting,
      globalFilter: globalTableSearchText,
      columnVisibility,
      pagination: pagination,
      expanded,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalSearchText,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows as any,
    getExpandedRowModel: getExpandedRowModel(),
    paginateExpandedRows: false,
    filterFromLeafRows: true,
    getRowCanExpand: (row) => row.subRows && row.subRows.length > 0,
  });

  const handleToggleEachColumnVisibility = (visibleColumns: string[]) => {
    table.getAllLeafColumns().map((column) => {
      if (visibleColumns.includes(column.columnDef.meta as string)) {
        column.toggleVisibility(true);
      } else {
        column.toggleVisibility(false);
      }
    });
  };

  // Toggle index column visibility on mobile
  useEffect(() => {
    if (isResponsived) {
      table.setColumnVisibility((prev) => ({
        ...prev,
        index: !isResponsived,
      }));
    }
  }, [table, isResponsived]);

  const visibleLeafColumns = table
    .getVisibleLeafColumns()
    .filter((column) => {
      if (HIDDEN_COLUMNS.includes(column.id)) {
        return isMyInvestment;
      }
      return true;
    })
    .map((column) => column.columnDef.meta as string);

  const defaultVisibleColumns = useMemo(() => {
    return table
      .getAllLeafColumns()
      .filter((column) => {
        if (HIDDEN_COLUMNS.includes(column.id)) {
          return isMyInvestment;
        }
        return true;
      })
      .map((column) => column.columnDef.meta as string);
  }, [table, isMyInvestment]);

  const handleSubmit = (values: MyPortfolioTableFilterFormValues) => {
    handleToggleEachColumnVisibility(values['visibleColumns']);
    onCloseModal && onCloseModal();
  };

  if (isMemberInvestmentLoading) {
    return (
      <div className='relative min-h-80'>
        <LoadingOverlay
          visible={isMemberInvestmentLoading}
          overlayProps={{
            backgroundOpacity: 0,
          }}
          loaderProps={{ color: 'red' }}
        />
      </div>
    );
  }

  if (memberInvestment.length === 0) {
    return <div>No investments have been made just yet.</div>;
  }

  return (
    <div className='flex flex-col h-full'>
      <div className='pb-12 hidden lg:block'>
        <MultiSelect
          withCheckIcon={true}
          placeholder={''}
          data={defaultVisibleColumns}
          nothingFoundMessage='Nothing found...'
          radius={'8px'}
          classNames={{
            input: 'rounded-xl',
          }}
          onChange={(value) => {
            handleToggleEachColumnVisibility(value);
          }}
          className='mb-4'
          value={visibleLeafColumns}
        />
      </div>

      <ScrollArea
        className='overflow-x-auto flex flex-col rounded-2xl relative flex-1'
        classNames={{ viewport: 'flex items-center md:items-stretch' }}
      >
        <TableView
          tableInstance={table}
          paginationConfig={{
            pageIndex: true,
            pageSize: true,
          }}
        />
      </ScrollArea>
      {isResponsived && (
        <TableFilterModalForm
          isModalOpen={!!isModalOpen}
          onCloseModal={onCloseModal}
          tableInstance={table}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
export default memo(InvesmentTable);
