import { useLogoutAPI } from '@api/mutations/useLoginWithLinkedin';
import { useAuth } from '@provider/AuthContext';
import { useCallback, useMemo } from 'react';

const useLogout = () => {
  const { mutate } = useLogoutAPI();
  const { setUser } = useAuth();

  const email = useMemo(() => {
    const localStorageData = localStorage.getItem('xa-network');
    return localStorageData ? JSON.parse(localStorageData).email : null;
  }, []);

  const handleLogout = useCallback(async () => {
    if (!email) return;
    await mutate({ email });
    setUser(null);
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  }, [email, mutate, setUser]);

  return { handleLogout };
};

export default useLogout;
