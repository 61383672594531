import { useSendGA } from '@hooks/useSendGA';
import { useLocation } from '@tanstack/react-router';
import { cn, isEntityDetailPath } from '@utils/utils';
import React, { forwardRef } from 'react';
import tw from 'tailwind-styled-components';

const MainLayoutContainer = tw.div`
  p-5 text-[#1A1A1B] h-full
  lg:p-10
`;

type MainLayoutProps = { children: React.ReactNode; className?: string; isNoPaddingPage?: boolean };

const MainLayout = forwardRef<HTMLDivElement, MainLayoutProps>(
  ({ children, className, isNoPaddingPage = false }, ref) => {
    useSendGA({});
    const location = useLocation();
    const shouldShowDisclaimer = isEntityDetailPath(location.pathname);

    return (
      <MainLayoutContainer
        ref={ref}
        className={cn(
          className,
          {
            'p-0 lg:p-0': isNoPaddingPage,
          },
          shouldShowDisclaimer ? '!pb-0' : '',
        )}
      >
        {children}
        {shouldShowDisclaimer && (
          <div className='flex items-center py-2 justify-start lg:justify-end'>
            <h1 className='text-xs lg:text-base text-justify '>
              <span className='font-bold'>Disclaimer:</span> The valuation data displayed on this
              screen is sourced from Alternatives.pe. Our sole responsibility is to ensure that
              information has been accurately and correctly extracted from the said source. We have
              not made any investigation of, and do not express or imply any views as to, the
              accuracy, correctness and/or completeness of the information. As such, we do not
              accept responsibility for any incorrect reporting. Further, we disclaim any obligation
              to advise you of any change or update to the information.
            </h1>
          </div>
        )}
      </MainLayoutContainer>
    );
  },
);

export default MainLayout;
