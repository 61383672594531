import type { ExpandLink, SubLink } from '@constants/nav';
import { UnstyledButton, Box, Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useSideBarStore } from '@store/useSideBarStore';
import { Link, useLocation } from '@tanstack/react-router';
import { cn } from '@utils/utils';
import { useEffect } from 'react';
import tw from 'tailwind-styled-components';

export const HierachyLine = tw.div`absolute left-0 top-[6px] bottom-4 w-[5px] border-l border-b rounded-bl-lg border-unactive-link opacity-70`;

type ExpandNavLinkProps = {
  isCollapsed?: boolean;
} & ExpandLink;

const ExpandNavLink = ({
  isCollapsed = false,
  links,
  label,
  icon: Icon,
  expandIcon: ExpandIcon,
}: ExpandNavLinkProps) => {
  const [isApplicationsFormOpen, setIsApplicationsFormOpen, isExpand, setIsExpanded] =
    useSideBarStore((state) => [
      state.isApplicationsFormOpen,
      state.setIsApplicationsFormOpen,
      state.isExpanded,
      state.setIsExpanded,
    ]);
  const [opened, { close, toggle }] = useDisclosure(isApplicationsFormOpen);
  const { pathname } = useLocation();

  const isActive = links.some(({ link }) => link === pathname);
  const subLinks = links.map((link) => <SubNavLink {...link} key={link.link} />);

  const handleExpandlinkClick = () => {
    toggle();
    setIsApplicationsFormOpen(!opened);

    if (!opened) {
      setIsExpanded(!opened);
    }
  };

  useEffect(() => {
    if (!isExpand) {
      setIsApplicationsFormOpen(false);
      close();
    }
  }, [isExpand, setIsApplicationsFormOpen, close]);

  return (
    <div>
      <UnstyledButton
        onClick={handleExpandlinkClick}
        className={cn(
          'flex w-full items-center text-unactive-link transition px-4 py-3 rounded-xl hover:bg-dark-red hover:text-white',
          {
            'bg-dark-red text-white': isActive,
            'justify-center': isCollapsed,
          },
        )}
      >
        <Box className='flex items-center justify-center gap-2.5 w-full'>
          {Icon && <Icon stroke={1.5} width={26} height={26} className='shrink-0' />}

          {!isCollapsed && (
            <div className='flex justify-between items-center w-full'>
              <span>{label}</span>
              {ExpandIcon && (
                <ExpandIcon
                  stroke={1.5}
                  width={20}
                  height={20}
                  className={cn('shrink-0 transition duration-300', { 'rotate-90': opened })}
                />
              )}
            </div>
          )}
        </Box>
      </UnstyledButton>
      <Collapse in={opened}>{subLinks}</Collapse>
    </div>
  );
};

const SubNavLink = ({ link, label }: SubLink) => {
  return (
    <UnstyledButton className='flex w-full items-center pl-6 pr-2 text-unactive-link  transition rounded hover:bg-lig hover:text-white'>
      <Box className='w-full'>
        <Link
          to={link}
          href={link}
          search={{}}
          className='relative flex items-center gap-2.5 p-3 text-sm hover:bg-sublink-hover w-full'
          activeProps={{
            className: 'text-white',
          }}
        >
          <span>{label}</span>
          <HierachyLine />
        </Link>
      </Box>
    </UnstyledButton>
  );
};

export default ExpandNavLink;
