import { refreshToken } from '@api/mutations/useLoginWithLinkedin';
import { config } from '@constants/config';
import { useSessionExpired } from '@hooks/useSessionExpired';
import { useAuth } from '@provider/AuthContext';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { createContext, useContext, useEffect } from 'react';

const getToken = () => {
  const token = localStorage.getItem('xa-network');
  return token ? JSON.parse(token).jwt : '';
};

const TIMEOUT = 60000;

const axiosInstance = axios.create({
  baseURL: config.NODE_ENV === 'development' ? '/api' : config.AXIOS_BASE_URL,
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export interface AxiosContextType {
  axiosInstance: AxiosInstance;
}

export const AxiosContext = createContext<AxiosContextType>({
  axiosInstance,
});

export const AxiosProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, setUser } = useAuth();
  const { setSessionExpired } = useSessionExpired();

  useEffect(() => {
    axiosInstance.interceptors.request.use((config) => {
      const token = getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError) => {
        const { status, config, data } = (error.response as AxiosResponse) ?? {};

        if (status === 401) {
          if (
            config.url === '/auth/login/refresh-token' ||
            data.message === 'Invalid or expired token.'
          ) {
            localStorage.clear();
            window.location.href = '/';

            setSessionExpired();
            return Promise.reject(error);
          }

          if (data.message === 'Unauthorized') {
            if (!user?.jwt) {
              return Promise.reject(error);
            }

            const decodedJwt = (() => {
              try {
                return JSON.parse(atob(user.jwt.split('.')[1]));
              } catch {
                return null;
              }
            })();

            if (!decodedJwt || decodedJwt.exp * 1000 >= Date.now()) {
              return Promise.reject(error);
            }

            try {
              const storedValue = localStorage.getItem('xa-network');
              const storageData = storedValue ? JSON.parse(storedValue) : null;

              if (!storageData?.refresh_token) {
                return Promise.reject(error);
              }
              const refreshData = await refreshToken(axiosInstance, {
                refresh_token: storageData.refresh_token,
              });

              setUser((prev) => (prev ? { ...prev, jwt: refreshData?.access_token } : null));

              localStorage.setItem(
                'xa-network',
                JSON.stringify({
                  ...storageData,
                  jwt: refreshData?.access_token,
                  refresh_token: refreshData?.refresh_token,
                }),
              );

              config.headers.Authorization = `Bearer ${refreshData?.access_token}`;
              return axiosInstance(config);
            } catch (refreshError) {
              return Promise.reject(refreshError);
            }
          }
        }

        return Promise.reject(error);
      },
    );
  }, [setSessionExpired, setUser, user]);

  return <AxiosContext.Provider value={{ axiosInstance }}>{children}</AxiosContext.Provider>;
};

export const useAxiosClient = () => {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error('');
  }
  return context;
};
