import { config } from '@constants/config';
import { useLocation } from '@tanstack/react-router';
import { EntryAnalyticsType, generateInformationGA } from '@utils/utils';
import ReactGA from 'react-ga4';

type SendGAProps = {
  entryAnalytics?: EntryAnalyticsType;
};
export const useSendGA = ({ entryAnalytics }: SendGAProps) => {
  const location = useLocation();

  const analyticInfo = generateInformationGA(location.pathname, entryAnalytics);

  if (!analyticInfo) {
    return;
  }
  if (config.NODE_ENV !== 'production') {
    return;
  }
  return ReactGA.send(analyticInfo);
};
