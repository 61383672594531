import { createFileRoute, redirect } from '@tanstack/react-router';
import isEmpty from 'lodash/isEmpty';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context }) => {
    const userAuth = context.authentication?.user?.jwt;
    if (!userAuth || isEmpty(userAuth?.trim())) {
      throw redirect({ to: '/login' });
    }
  },
});
