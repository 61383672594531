import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function normalizeUserData(data: any) {
  // Normalize keys to lowercase
  return Object.keys(data).reduce((acc: any, key: string) => {
    acc[key.toLowerCase()] = data[key];
    return acc;
  }, {});
}

export const isEntityDetailPath = (path: string) => {
  const regex = /^\/my-portfolio\/?$/;
  return regex.test(path);
};

const INFORMATION_ROUTE: Record<string, string> = {
  '/members': 'Members Page',
  '/login': 'Login Page',
  '/forms/member-application': 'Member Application Forms',
  '/forms/startup-submission': 'Startup Submission Form Page',
  '/help': 'Help Page',
  '/home': 'Home Page',
  '/my-portfolio': 'My Portfolio Page',
  '/terms': 'Terms Page',
  '/my-profile': 'My Profile Page',
};

export type EntryAnalyticsType = {
  companyName?: string;
  investOpId?: string;
  memberName?: string;
};
export const generateInformationGA = (pathname: string, entryAnalytic?: EntryAnalyticsType) => {
  if (entryAnalytic?.companyName || entryAnalytic?.memberName) {
    return {
      hitType: 'pageview',
      page: pathname,
      title: entryAnalytic.companyName
        ? `${entryAnalytic.companyName} Detail Page (${entryAnalytic?.investOpId})`
        : `${entryAnalytic.memberName} Detail Page`,
    };
  }

  const title = INFORMATION_ROUTE[pathname];
  if (title) {
    return {
      hitType: 'pageview',
      page: pathname,
      title,
    };
  }

  return undefined;
};
