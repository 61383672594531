import SvgFilter from '@assets/icons/Filter';
import { InputWithButton } from '@components/pages/members/components/InputWithButton';
import { Button, CloseButton, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from '@provider/AuthContext';
import { useGetInvestmentByXAMemberId } from '@queries/bases/xa-network-pipeline/useXaNetworkPipeline';
import { useTableStore } from '@store/useTableStore';
import InvesmentTable from '@tables/InvestmentTable';
import { createFileRoute } from '@tanstack/react-router';
import { useCallback, useEffect } from 'react';
import tw from 'tailwind-styled-components';

export const Route = createFileRoute('/_authenticated/(my-portfolio)/my-portfolio')({
  component: () => <MyPortfolio />,
});

const MyPortfolioContainer = tw.div`relative  bg-secondary-gray rounded-3xl p-5 pb-7 md:pb-8 lg:pb-12 md:p-8 lg:p-12 flex flex-col`;

function MyPortfolio() {
  const { user } = useAuth();
  const [globalSearchText, setGlobalSearchText] = useTableStore((state) => [
    state.globalTableSearchText,
    state.setGlobalSearchText,
  ]);
  const [opened, { close, toggle }] = useDisclosure(false);
  const {
    data: memberInvestment = [],
    isFetched,
    isFetching,
  } = useGetInvestmentByXAMemberId(user?.recordId, 'my-portfolio');

  const handleClearText = useCallback(() => {
    setGlobalSearchText('');
  }, [setGlobalSearchText]);

  useEffect(() => {
    return () => handleClearText();
  }, [handleClearText]);

  if (isFetching) {
    return (
      <MyPortfolioContainer className='relative h-full'>
        <LoadingOverlay loaderProps={{ color: 'red' }} visible={isFetching} className='z-header' />
      </MyPortfolioContainer>
    );
  }

  return (
    <>
      <MyPortfolioContainer>
        <h2 className='font-Roobert relative font-semibold text-2xl leading-8 lg:text-[40px] lg:leading-[38px] mb-3 lg:mb-4 items-center gap-2 flex'>
          My Portfolio
        </h2>
        <h2 className='font-sans font-normal text-base leading-6 w-full mb-8 lg:mb-9'>
          View your investments and their details.
        </h2>

        {isFetched && memberInvestment.length > 0 && (
          <div className='flex flex-col flex-1'>
            <div className='relative flex gap-3 justify-between items-center'>
              <Button className='bg-white py-2.5 px-4 h-full lg:hidden' onClick={toggle}>
                <SvgFilter />
              </Button>
              <InputWithButton
                className='items-center flex-1 lg:flex-none'
                onChange={(e) => {
                  setGlobalSearchText(e.target.value);
                }}
                value={globalSearchText}
                rightSection={
                  globalSearchText ? (
                    <CloseButton aria-label='Clear input' onClick={handleClearText} />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            {user && (
              <div className='mt-4 flex-1'>
                <InvesmentTable
                  userRecordId={user.recordId}
                  type='my-portfolio'
                  isModalOpen={opened}
                  onCloseModal={close}
                />
              </div>
            )}
          </div>
        )}
        <div className='absolute bottom-0 right-0 h-7 md:h-8 lg:h-12 flex items-center pr-5 md:pr-8 lg:pr-12'>
          <h1 className={'text-xs lg:text-lg font-semibold'}>Powered by Alternatives.pe</h1>
        </div>
      </MyPortfolioContainer>
    </>
  );
}
