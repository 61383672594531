import { DEFAULT_PADDING_LEFT_INPUT_SEARCH } from '@constants/memberFilter';
import {
  TextInput,
  TextInputProps,
  ActionIcon,
  useMantineTheme,
  rem,
  CloseButton,
} from '@mantine/core';
import { IconSearch, IconArrowRight } from '@tabler/icons-react';
import { truncateString } from '@utils/helpers';
import { useEffect, useRef, useState } from 'react';

type InputWithButtonProps = TextInputProps & {
  isResponsived?: boolean;
  historyItem?: string;
  onClickHistoryItem?: () => void;
  onClickDeleteHistoryItem?: () => void;
};
export function InputWithButton(props: InputWithButtonProps) {
  const theme = useMantineTheme();

  const historyRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (historyRef.current) {
      setWidth(historyRef.current.offsetWidth);
    }
  }, [props.historyItem]);

  return (
    <div className={`${props.isResponsived ? 'w-full' : 'w-fit'} relative`}>
      <TextInput
        radius='8px'
        placeholder='Search'
        classNames={{
          input: 'h-[44px]',
        }}
        styles={{
          input: {
            paddingLeft:
              props.isResponsived && props.historyItem
                ? `${DEFAULT_PADDING_LEFT_INPUT_SEARCH + width}px`
                : undefined,
          },
        }}
        rightSectionWidth={42}
        leftSection={<IconSearch style={{ width: rem(18), height: rem(18) }} stroke={1.5} />}
        rightSection={
          <ActionIcon size={32} radius='xl' color={theme.primaryColor} variant='filled'>
            <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
        }
        {...props}
        value={props.historyItem ? '' : props.value}
      />
      {props.isResponsived && props.historyItem && (
        <div
          ref={historyRef}
          className=' absolute top-1/2 left-0  ml-[34px] -translate-y-1/2 z-50 bg-medium-gray/70 px-2 py-0.5 rounded-full flex items-center gap-1'
          onClick={props.onClickHistoryItem}
        >
          {truncateString(props.historyItem)}
          <CloseButton
            size='xs'
            aria-label='Clear history'
            onClick={props.onClickDeleteHistoryItem}
          />
        </div>
      )}
    </div>
  );
}
