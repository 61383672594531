import { COMPANY_STATUS, COMPAY_SECTOR, SPV } from '@constants/enums';
import { CEASED_OPERATIONS } from '@type/InvestmentTable/type';
import { cn } from '@utils/utils';

interface MoicColumnProps {
  investmentAmount: number;
  latestSharePrice: number;
  totalShares?: number;
  sector: string[];
  status?: COMPANY_STATUS;
  lastValuation?: number;
  companyID?: string;
  spv?: SPV;
  name: string;
  ceasedOperations?: CEASED_OPERATIONS;
  numberOfShares?: number;
}

const MoicColumn = ({
  investmentAmount,
  latestSharePrice,
  sector,
  companyID,
  spv,
  ceasedOperations,
  totalShares,
}: MoicColumnProps) => {
  if (sector?.includes(COMPAY_SECTOR.VC)) {
    return <span className={cn('text-primary-black')}>N/A</span>;
  }

  if (ceasedOperations === CEASED_OPERATIONS.Y) {
    return <span className={cn('text-primary-black')}>0</span>;
  }

  if (!companyID || !totalShares) {
    return <span className={cn('text-primary-black')}>{spv === SPV.YES ? '-' : '1.0'}</span>;
  }

  const moic = (totalShares * latestSharePrice) / investmentAmount;
  return <span className={cn('text-primary-black')}>{moic.toFixed(1)}</span>;
};

export default MoicColumn;
