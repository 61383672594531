import { ResponseAPIType } from '@api/common.type';
import { MUTATION_KEY } from '@constants/mutationKeys';
import { useAxiosClient } from '@provider/AxiosContext';
import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import {
  RequestRefeshTokenType,
  ResponseLoginLinkedInType,
  ResponseRefeshTokenType,
  ResultLoginLinkedInType,
} from './mutation.type';

export function useLoginWithLinkedin() {
  const { axiosInstance } = useAxiosClient();
  return useMutation<ResultLoginLinkedInType, Error, string>({
    mutationKey: [MUTATION_KEY.LOGIN_LINKEDIN],
    mutationFn: async (email: string) => {
      const response: ResponseLoginLinkedInType = await axiosInstance.post('/auth/login/linkedin', {
        email,
      });
      return response.data;
    },
  });
}

export const refreshToken = async (
  axiosInstance: AxiosInstance,
  { refresh_token }: RequestRefeshTokenType,
) => {
  try {
    const response: ResponseRefeshTokenType = await axiosInstance.post(
      '/auth/login/refresh-token',
      {
        refresh_token,
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export function useLogoutAPI() {
  const { axiosInstance } = useAxiosClient();
  return useMutation({
    mutationKey: [MUTATION_KEY.LOGOUT],
    mutationFn: async ({ email }: { email: string }): Promise<ResponseAPIType<any>> => {
      const response = await axiosInstance.post('/auth/logout', {
        email,
      });
      return response.data;
    },
  });
}
