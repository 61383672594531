import { QUERY_KEY } from '@constants/queryKeys';
import { useAxiosClient } from '@provider/AxiosContext';
import { useQuery } from '@tanstack/react-query';

export function useGetCurrentUserProfile(userRecordId?: string) {
  const { axiosInstance } = useAxiosClient();
  return useQuery({
    queryKey: [QUERY_KEY.GET_MY_PROFILE, userRecordId],
    queryFn: async () => {
      const response = await axiosInstance.get('/my-profile', {
        params: {
          userId: userRecordId,
        },
      });
      return await response.data;
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !!userRecordId,
    retry: 3,
  });
}
