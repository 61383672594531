import { MUTATION_KEY } from '@constants/mutationKeys';
import { useAxiosClient } from '@provider/AxiosContext';
import { useMutation } from '@tanstack/react-query';

export function useEditMyPortfolioMyProfile(onSuccess?: () => void) {
  const { axiosInstance } = useAxiosClient();
  return useMutation({
    mutationKey: [MUTATION_KEY.EDIT_MY_PORTFOLIO_MY_PROFILE],
    mutationFn: async (data: any) => {
      const response = await axiosInstance.patch('/my-portfolio/my-profile', data);
      return response;
    },
    onSuccess: () => {
      onSuccess && onSuccess();
    },
  });
}
export function useSubmitFeedback(userId?: string) {
  const { axiosInstance } = useAxiosClient();
  return useMutation({
    mutationKey: [MUTATION_KEY.SUBMIT_FEEDBACK, userId],
    mutationFn: async (data: any) => {
      const response = await axiosInstance.post('/feedback', data);
      return response;
    },
  });
}
