export const useSessionExpired = () => {
  const localStorageData = localStorage.getItem('session-expired');
  const parsedData = localStorageData
    ? (JSON.parse(localStorageData) as { isSessionExpired: boolean })
    : null;

  const clearSession = () => {
    localStorage.removeItem('session-expired');
  };

  const setSessionExpired = () => {
    localStorage.setItem('session-expired', JSON.stringify({ isSessionExpired: true }));
  };

  return {
    clearSession,
    setSessionExpired,
    isSessionExpired: parsedData?.isSessionExpired || false,
  };
};
