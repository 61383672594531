export type StepItemType = {
  label: string;
  description: string;
};

export const LOGIN_STEPS: StepItemType[] = [
  {
    label: 'Step 1',
    description: 'Login with Linkedin',
  },
  {
    label: 'Step 2',
    description: 'Verify email',
  },
  {
    label: 'Step 3',
    description: 'Get full access',
  },
];
