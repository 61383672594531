export enum MUTATION_KEY {
  LOGIN_LINKEDIN = 'LOGIN_LINKEDIN',
  LOGOUT = 'LOGOUT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  ACCEPT_TERMS_OF_USE = 'ACCEPT_TERMS_OF_USE',
  EDIT_MY_PORTFOLIO_MY_PROFILE = 'EDIT_MY_PORTFOLIO_MY_PROFILE',
  SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK',
  CANCEL_INTEREST = 'CANCEL_INTEREST',
  EXPRESS_INTEREST = 'EXPRESS_INTEREST',
}
