import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface User {
  // Linkedin user
  email: string;
  recordId: string;
  jwt?: string;
  name?: string;

  firstName?: string;
  lastName?: string;
  locale?: {
    country?: string;
    language?: string;
  };
  picture?: string;

  profilePhoto?: string;
  preferredName?: string;
  titleRole?: string;
  shortBio?: string;
  location?: string;
  areaOfExpertise?: string[];
  isTermOfUseAccepted?: boolean;
}

export interface AuthContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isRefetching: boolean;
  setIsRefetching: React.Dispatch<React.SetStateAction<boolean>>;
}
const AuthContext = createContext<AuthContextProps>({
  setUser: () => {},
  user: null,
  isLoading: false,
  setIsLoading: () => {},
  isRefetching: false,
  setIsRefetching: () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(
    localStorage.getItem('xa-network')
      ? JSON.parse(localStorage.getItem('xa-network') as string)
      : null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const value = useMemo(
    () => ({
      user,
      setUser,
      isLoading,
      setIsLoading,
      isRefetching,
      setIsRefetching,
    }),
    [user, isLoading, isRefetching],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
