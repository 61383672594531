export const DEFAULT_PAGE_SIZE = 12;
export const DEFAULT_PAGE_VIEW = 1;

export const DELIMITER = ',';

export const DEFAULT_PADDING_LEFT_INPUT_SEARCH = 36;
export const THRESHOLD_LENGTH_HISTORY = 24;

export const DEFAULT_SEARCH_PARAMS: Record<string, string | string[]> = {
  investorName: '',
  sortByName: '',
  company: [] as string[],
  industryExpertise: [] as string[],
  location: [] as string[],
  page: '',
  memberPerPage: '',
};
