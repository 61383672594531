import { PropsWithChildren } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

type ErrorBoundaryContainerProps = PropsWithChildren & {
  fallbackComponent?: React.ReactNode;
};

const DefaultFallbackComponent = ({ error }: FallbackProps) => (
  <div>
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
  </div>
);

const ErrorBoundaryContainer = ({ children, fallbackComponent }: ErrorBoundaryContainerProps) => {
  return (
    <ErrorBoundary
      FallbackComponent={fallbackComponent ? () => fallbackComponent : DefaultFallbackComponent}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryContainer;
