import { COMPANY_STATUS, COMPAY_SECTOR, SPV } from '@constants/enums';
import { CEASED_OPERATIONS } from '@type/InvestmentTable/type';
import { formatCurrencyWithoutFractionDigits } from '@utils/helpers';
import { cn } from '@utils/utils';

interface CurrentValueColumnProps {
  totalShares?: number;
  latestSharePrice: number;
  investmentAmount: number;
  sector: string[];
  status?: COMPANY_STATUS;
  lastValuation?: number;
  companyID?: string;
  spv?: SPV;
  name: string;
  ceasedOperations?: CEASED_OPERATIONS;
  numberOfShares?: number;
}

const CurrentValueColumn = ({
  latestSharePrice,
  investmentAmount,
  sector,
  spv,
  companyID,
  ceasedOperations,
  totalShares,
}: CurrentValueColumnProps) => {
  if (sector?.includes(COMPAY_SECTOR.VC)) {
    return <span className={cn('text-primary-black')}>Not Applicable</span>;
  }

  if (ceasedOperations === CEASED_OPERATIONS.Y) {
    return <span className={cn('text-primary-black')}>$0</span>;
  }
  if (!companyID || !totalShares) {
    return (
      <span className={cn('text-primary-black')}>
        {spv === SPV.YES
          ? 'Not Yet Available'
          : formatCurrencyWithoutFractionDigits(investmentAmount)}
      </span>
    );
  }
  const currentValue = totalShares * latestSharePrice;
  return (
    <span className={cn('text-primary-black')}>
      {formatCurrencyWithoutFractionDigits(currentValue)}
    </span>
  );
};

export default CurrentValueColumn;
