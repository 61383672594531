import { createFileRoute, redirect } from '@tanstack/react-router';
import isEmpty from 'lodash/isEmpty';
import { LoginForm } from '../components/AuthenticationForm/Login';

export const Route = createFileRoute('/login')({
  beforeLoad: async ({ context }) => {
    const userAuth = context.authentication?.user?.jwt?.trim();
    if (!isEmpty(userAuth)) {
      throw redirect({ to: '/home' });
    }
  },
  component: () => <Login />,
});
const Login = () => {
  return (
    <div className='flex flex-col items-center justify-center h-full col-span-2 px-6'>
      <LoginForm />
    </div>
  );
};

export default Login;
