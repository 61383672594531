import { Table } from '@tanstack/react-table';
import { FormFilterConfig } from '@type/InvestmentTable/type';
import { useEffect, useMemo } from 'react';
import {
  PortfolioFilterFormProvider,
  MyPortfolioTableFilterFormValues,
  usePortfolioFilterForm,
} from './TableFilterContext';
import TableFiltersModal from './TableFiltersModal';

interface TableFilterModalFormProps {
  tableInstance: Table<any>;
  isModalOpen: boolean;
  onSubmit: (values: MyPortfolioTableFilterFormValues) => void;
  onCloseModal?: () => void;
}

const TableFilterModalForm = ({
  tableInstance,
  isModalOpen,
  onSubmit,
  onCloseModal,
}: TableFilterModalFormProps) => {
  const visibleLeafColumns = tableInstance.getVisibleLeafColumns();

  const defaultFormValues = useMemo(() => {
    return {
      visibleColumns: visibleLeafColumns.map((column) => column.columnDef.meta as string),
    };
  }, [visibleLeafColumns]);

  const form = usePortfolioFilterForm({
    mode: 'uncontrolled',
    initialValues: {
      visibleColumns: tableInstance
        .getAllFlatColumns()
        .map((column) => column.columnDef.meta as string),
    },
  });

  const { setValues, reset } = form;

  useEffect(() => {
    setValues(defaultFormValues);

    return () => {
      reset();
    };
  }, [defaultFormValues, reset, setValues]);

  const formsConfig: FormFilterConfig = useMemo(() => {
    return {
      forms: [
        {
          name: 'visibleColumns',
          type: 'checkbox',
          label: 'Visible Columns',
        },
      ],
    };
  }, []);

  return (
    <PortfolioFilterFormProvider form={form}>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <TableFiltersModal
          isOpen={!!isModalOpen}
          onClose={onCloseModal}
          formsConfig={formsConfig}
          formInstance={form}
        />
      </form>
    </PortfolioFilterFormProvider>
  );
};

export default TableFilterModalForm;
