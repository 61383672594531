import { createContext, ReactNode, RefObject, useContext, useMemo, useRef } from 'react';

export interface RootContext {
  pageTopRef: RefObject<HTMLDivElement | null>;
}
const RootContext = createContext<RootContext>({
  pageTopRef: { current: null } as RefObject<HTMLDivElement | null>,
});

export function AppProvider({ children }: { children: ReactNode }) {
  const pageTopRef = useRef<HTMLDivElement | null>(null);

  const value = useMemo(
    () => ({
      pageTopRef,
    }),
    [pageTopRef],
  );

  return <RootContext.Provider value={value}>{children}</RootContext.Provider>;
}

export function useRootContext() {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error('useRootContext must be used within an RootProvider');
  }
  return context;
}
